<template>
  <div class="solutions">
    <SolutionDashboard id="solutions-pg" />
    <SolutionFeatures />
    <GetStarted />
    <!-- <SolutionTpay /> -->
  </div>
</template>

<script>
import SolutionDashboard from "../components/SolutionsTverifyComponents/SolutionDashboard.vue";
import SolutionFeatures from "../components/SolutionsTverifyComponents/SolutionFeatures.vue";
import GetStarted from "../components/GetStarted";
// import SolutionTpay from "../components/SolutionTpay.vue";

export default {
  name: "Solutions",
  components: {
    SolutionDashboard,
    SolutionFeatures,
    GetStarted,
    // SolutionTpay,
  },
  data() {
    return {
      title:
        "Tekkis Digital Identity Verification Solutions - eKYC with Face Recognition technology",
      description:
        "Digitally verify real customers anytime, anywhere in 30-secs. Seamless eKYC using MyKad and face biometrics. Increase your platform's security today! Custom solutions available.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Digitally verify real customers anytime, anywhere in 30-secs. Seamless eKYC using MyKad and face biometrics. Increase your platform's security today! Custom solutions available.";
    },
  },
};
</script>
